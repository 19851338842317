import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';


/** import all components */
import Username from './components/Username';
import Password from './components/Password';
import Register from './components/Register';
import Profile from './components/Profile';
import Recovery from './components/Recovery';
import Reset from './components/Reset';
import PageNotFound from './components/PageNotFound';
import Home from './components/Home';
import Payment from './components/Payment';
import Dashboard from './components/Dashboard';
import Workshop from './components/Workshop';
import Workregister from './components/WorkshopRegister';
import Worksuccessful from './components/Worksuccessful';
import AbujaPayment from './components/AbujaPayment';
/** auth middleware */
import { AuthorizeUser, ProtectRoute } from './middleware/auth';
import Reciept from './components/Reciept'
import MealTag from './components/Mealtag';
import Agmtag from './components/Agmtag';
import Registered from './components/Registered';
import Excoregister from "./components/Excoregister"
import Resources from "./components/Resources"
import Certificate from './components/Certificate';
import PaymentCheck from './components/PaymentCheck';
import MealTickets from './components/MealTickets.js';

/** root routes */
const router = createBrowserRouter([
    {
        path : '/',
        // element : <Username></Username>
        element: <Home></Home>
        // element: <Workshop></Workshop>
    },
    {
        path : '/register',
        element : <Register></Register>
    },
    {
        path : '/password',
        element : <ProtectRoute><Password /></ProtectRoute>
    },
    {
        path: '/profile',
        element: <Profile></Profile>,
      },
      
    {
        path : '/recovery',
        element : <Recovery></Recovery>
    },
    {
        path : '/reset',
        element : <Reset></Reset>
    },
    {
        path : '*',
        element : <PageNotFound></PageNotFound>
    },
    {
        path : '/payment',
        element: <Payment></Payment>
    },
    {path : '/abujaattendees',
    element: <AbujaPayment></AbujaPayment>
    },
    {
        path : '/login',
        element: <Username></Username>
    },
    {
        path: '/dashboard',
        element: <ProtectRoute><Dashboard /></ProtectRoute>
    },
    {
        path: '/reciept',
        element:<Reciept></Reciept>
    },
    {
        path: '/mealtag/:mealType',
        element:<MealTag></MealTag>
    },
    {
        path: '/agmtag',
        element:<Agmtag></Agmtag>
    },

    {
        path: '/registereduserslist',
        element:<Registered></Registered>
    },
    {
        path: '/adminregistration909390',
        element:<Excoregister></Excoregister>
    },

    {
        path: '/resources',
        element: <Resources></Resources>
    },
    {
        path: '/certificate',
        element: <Certificate></Certificate>
    },
    {
        path: '/workshop',
        element: <Workshop></Workshop>
    },
    {
        path: '/workshopregistration',
        element: <Workregister></Workregister>
    },
    {
        path: '/workshopregistrationsuccess',
        element: <Worksuccessful></Worksuccessful>
    },
    {
        path: '/payment-check',
        element: <PaymentCheck></PaymentCheck>
    },
    {
        path: '/meal-tickets',
        element: <MealTickets></MealTickets>
    }
  


])

export default function App() {
  return (
    <main>
        <RouterProvider router={router}></RouterProvider>
    </main>
  )
}
