import React, { useState, useEffect } from 'react';
import { useAuthStore } from '../store/store';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Dashboard.css';

const Resources = () => {
  const { username } = useAuthStore((state) => state.auth);
  const [userData, setUserData] = useState({
    status: '',
    mobile: '',
  });

  useEffect(() => {
    // Fetch user data from the database
    axios
      .get(`/api/user/${username}`)
      .then(response => {
        const { status, mobile, firstName, lastName, profile, isInducted, inductionLocation } = response.data;
        setUserData({ status, mobile, firstName,lastName, profile, isInducted, inductionLocation });
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  }, []);


  const handleDownloadResources = (pdfFileUrl, fileName) => {
    // Create temporary anchor element
    const link = document.createElement('a');
  
    // Set the URL for the anchor element
    link.href = pdfFileUrl;
  
    // Add download attribute to trigger the download
    link.setAttribute('download', fileName);
  
    // Trigger the download
    link.click();
  };

  // Assuming you have different PDF file URLs and corresponding filenames for each resource
  const resources = [
    { url: 'assets/doc/nibifm_constitution.pdf', name: 'NIBIFM Constitution' },
    { url: 'assets/doc/keynote.pdf', name: 'Keynote Speakers' },
    {url: 'https://drive.google.com/file/d/1Dr0NxlzGqPUT3lKdejFTSxHRUK1WyyrD/view?usp=drive_link', name: 'Annual Report and Citation'},
    {url: 'https://drive.google.com/file/d/1fkx-aYhlcEMOTsscohJpG79odA2018eU/view?usp=drive_link', name: 'Proceedings 2024'},
    {url: 'assets/doc/Agm Auditors Report.pdf', name: 'AGM Auditors Report'},



    
    // Add more resources as needed
  ];

  // Conditional rendering: Render only if username is available

  return (
    <div className="dashboard">
      <h1 style={{ color: 'black' }}>Download Event Resources here, {username}!</h1>
      {resources.map((resource) => (
        <button key={resource.name} onClick={() => handleDownloadResources(resource.url, resource.name)}>
          {resource.name}
        </button>
      ))}
      <Link style={{backgroundColor: "#d9a157"}} to="/dashboard">
        <button style={{backgroundColor: "#d9a157"}}>Go back</button>
      </Link>
    </div>
  );
};

export default Resources;
