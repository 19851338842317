import React, { useState, useEffect } from 'react';
import { useAuthStore } from '../store/store';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Dashboard.css';

const MealTickets = () => {
  const { username } = useAuthStore((state) => state.auth);
  const [userData, setUserData] = useState({
    status: '',
    mobile: '',
    firstName: '',
    lastName: '',
    profile: '',
    isInducted: false,
    inductionLocation: ''
  });

  useEffect(() => {
    if (username) {
      axios
        .get(`/api/user/${username}`)
        .then(response => {
          const { status, mobile, firstName, lastName, profile, isInducted, inductionLocation } = response.data;
          setUserData({ status, mobile, firstName, lastName, profile, isInducted, inductionLocation });
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        });
    }
  }, [username]);

  // URLs for meal tickets
  const mealTickets = [
    { name: 'Breakfast Ticket', type: 'breakfast' },
    { name: 'Lunch Ticket', type: 'lunch' },
    { name: 'Dinner Ticket', type: 'dinner' }
  ];

  return (
    <div className="dashboard">
      <h1 style={{ color: 'black' }}>Download Your Meal Tickets Here, {username}!</h1>
      {mealTickets.map((ticket) => (
        <Link key={ticket.name} to={`/mealtag/${ticket.type}`}>
          <button>
            {ticket.name}
          </button>
        </Link>
      ))}
      <Link to="/dashboard">
        <button style={{ backgroundColor: "#d9a157" }}>Go back</button>
      </Link>
    </div>
  );
};

export default MealTickets;
