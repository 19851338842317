import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Toaster } from 'react-hot-toast';

import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <>
        <App />
        <Toaster  position="top-center" />
    </>
)