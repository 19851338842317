import React from 'react';
import axios from 'axios';
import { useAuthStore } from '../store/store';

import {useState, useEffect} from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './Reciept.css';



function Reciept() {
    
    const { username } = useAuthStore((state) => state.auth);
    const [userData, setUserData] = useState({
      status: '',
      mobile: '',
    });
    useEffect(() => {
        // Fetch user data from the database
        axios
          .get(`/api/user/${username}`)
          .then(response => {
            const { status, mobile, firstName, lastName, createdAt, amount, membershipNo } = response.data;
            setUserData({ status, mobile, firstName,lastName, createdAt, amount, membershipNo });
          })
          .catch(error => {
            console.error('Error fetching user data:', error);
          });
      }, []);
// Assuming userData is an object containing the retrieved data
const formattedCreatedAt = new Date(userData.createdAt).toLocaleString();

// Use the formattedCreatedAt variable wherever you need to display the formatted date



  const [loader, setLoader] = useState(false);

  const downloadPDF = () =>{
    const capture = document.querySelector('.actual-receipt');
    setLoader(true);
    html2canvas(capture).then((canvas)=>{
      const imgData = canvas.toDataURL('img/png');
      const doc = new jsPDF('p', 'mm', 'a6');
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
      setLoader(false);
      doc.save('nibifm receipt.pdf');
    })
  }

  return (
    <div className="wrapper">

      <div className="receipt-box">

          {/* actual receipt */}
          <div className="actual-receipt">

            {/* organization logo */}
            <div style={{marginTop: "2%"}}  className="receipt-organization-logo">
              <img style={{borderRadius: "50%"}} alt="logo" src='assets/images/logo.png' />
            </div>

            {/* organization name */}
            <h5>NIBIFM PAYMENT RECIEPT</h5>

           

        

            <div className="colored-row first">
              <span>Payment Method</span>
              <span>Online</span>
            </div>
            <div className="data-row">
              <span className="font-weight">UserName</span>
              <span>{username}</span>
            </div>

            <div className="data-row">
              <span className="font-weight">Name</span>
              <span>{userData.firstName} {userData.lastName}</span>
            </div>

            <div className="colored-row">
              <span>Purpose</span>
              <span>Amount</span>
            </div>

            <div className="data-row">
              <span className="font-weight">Conference Fee</span>
              <span>
                #
                {' '}
                {userData.amount}
              </span>
            </div>

            <div className="colored-row">
              <span>Transaction Details</span>
              <span />
            </div>

            <div className="data-row border-bottom">
              <span>
                <span className="font-weight">
                Attendance Mode
                  :
                </span>
                
              </span>
              <span>
                <span className="font-weight">
                
                </span>
                {' '}
                {userData.status}
              </span>
            </div>
            <div className="data-row border-bottom">
              <span>
                <span className="font-weight">
                Attendance Location
                  :
                </span>
                
              </span>
              <span>
                <span className="font-weight">
                
                </span>
                {' '}
                Anambra
              </span>
            </div>

            <div className="data-row border-bottom">
              <span>
                <span className="font-weight">
                  Phone:
               
                </span>
                {' '}
              </span>
              <span>
                <span className="font-weight">
                </span>
                {' '}
                {userData.mobile}
              </span>
            </div>
            <div className="data-row border-bottom">
              <span>
                <span className="font-weight">
                  Date:
                  {' '}
                  
                </span>
             
              </span>
              <span>
                <span className="font-weight">
            
                </span>
                {' '}
                {formattedCreatedAt}
              </span>
            </div>
            <div className="data-row border-bottom">
              <span>
                <span className="font-weight">
                  TRANSACTION
                  {' '}
                  
                </span>
             
              </span>
              <span>
                <span className="font-weight">
            
                </span>
                {' '}
                APPROVED
              </span>
            </div>
           
            <div className="colored-row">
              <span>Thank You</span>
              <span />
            </div>

          </div>
          {/* end of actual receipt */}

          {/* receipt action */}
          <div className="receipt-actions-div">
            <div className="actions-right">
              <button
                className="receipt-modal-download-button"
                onClick={downloadPDF}
                disabled={!(loader===false)}
              >
                {loader?(
                  <span>Downloading</span>
                ):(
                  <span>Download</span>
                )}

              </button> 
            </div>
          </div>

      </div>
      
    </div>
  );
}

export default Reciept;
