import React, { useState } from 'react';
import avatar from '../assets/profile.png';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import convertToBase64 from '../helper/convert';
import { registerUser } from '../helper/helper';
import { registerValidation } from '../helper/validate';

import styles from '../styles/Profile.module.css';
import extend from '../styles/Profile.module.css';
import './Profile.css';

export default function Excoregister() {
  const navigate = useNavigate();
  const location = useLocation();
  const [file, setFile] = useState('');
  const membershipNo = location.state?.membershipNo || '';

  const [paymentAmount, setPaymentAmount] = useState(0);
  const [selectedState, setSelectedState] = useState('');
  const [selectedAttendance, setSelectedAttendance] = useState('');

  const formik = useFormik({
    initialValues: {
      email: '',
      username: '',
      password: '',
      membershipNo: '',
      isInducted: false,
      inductionLocation: '',
      amount:'',
      status:'',
      profile: '',
    },
    validate: registerValidation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      console.log('Submitting form...', values);
      values = { ...values, amount: paymentAmount };
      // Show a toast message indicating registration is in progress
      const loadingToast = toast.loading('Registering...');
      try {
        const response = await registerUser(values);
        toast.success('Registered Successfully!');
        navigate('/login');
      } catch (error) {
        toast.error('Could not Register.');
      } finally {
        // Close the loading toast when registration is complete
        toast.dismiss(loadingToast);
      }
    },
  });

  const calculatePayment = (attendance) => {
    let baseAmount = 0;
    if (attendance === 'live') {
      baseAmount = 90000; // Assuming 90,000 is the amount for live attendance
    } else if (attendance === 'online') {
      baseAmount = 72000; // Assuming 72,000 is the amount for online attendance
    }
    setPaymentAmount(baseAmount);
    setSelectedAttendance(attendance); // Update selectedAttendance state
    formik.setFieldValue('status', attendance); // Update status field in formik initialValues
  };

  const handleAttendanceChange = (event) => {
    const attendance = event.target.value;
    setSelectedAttendance(attendance);
    calculatePayment(attendance); // Call calculatePayment with selected attendance type
  };

  const onUpload = async (e) => {
    const base64 = await convertToBase64(e.target.files[0]);
    setFile(base64);
    formik.setFieldValue('profile', base64);
    formik.setFieldTouched('profile', true);
  };

  return (
    <div className='register'>
      <div className="container mx-auto">
        <div className="flex justify-center items-center h-screen">
          <div
            className={`${styles.glass} ${extend.glass} w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3`}
            style={{ paddingTop: "3em", width: '100%' }}
          >
            <div className="title flex flex-col items-center">
              <h4 className="text-5xl font-bold">Welcome ADMIN...</h4>
              <h5 style={{ fontSize: "15px", color: "black" }} className="text-5xl font-bold">This is a private route for you to register attendees, please do not share this with anyone</h5>
            </div>
            <form className="py-1" onSubmit={formik.handleSubmit}>
              <div className="profile flex justify-center py-4">
                <label htmlFor="profile">
                  <img
                    src={avatar}
                    className={styles.profile_img}
                    alt="avatar"
                  />
                </label>
              </div>
              <div className="textbox flex flex-col items-center gap-6">
                <input
                  {...formik.getFieldProps("email")}
                  className={styles.textbox}
                  type="text"
                  placeholder="Email*"
                />
                <input
                  {...formik.getFieldProps("username")}
                  className={styles.textbox}
                  type="text"
                  placeholder="Username*"
                />
                <input
                  {...formik.getFieldProps("password")}
                  className={styles.textbox}
                  type="text"
                  placeholder="Password*"
                />

                <div style={{ textAlign: "center", color: "black", fontSize: "20px" }}>
                  <div className="mb-4">
                    <label htmlFor="state" className="text-lg font-bold" style={{ marginRight: "1%" }}>Select State:</label>
                    <select
                      id="state"
                      name="state"
                      value={formik.values.membershipNo}
                      onChange={(event) => {
                        formik.setFieldValue('membershipNo', event.target.value);
                        setSelectedState(event.target.value); // Optional: If you need to track the selected state in the component state
                      }}
                      className="border border-gray-300 rounded-lg p-2"
                    >
                      <option value="">Select State</option>
                      <option value="Abuja">Abuja</option>
                      <option value="Lagos">Lagos</option>
                    </select>
                  </div>
                  <div style={{ fontSize: "20px", color: "black" }} className="mb-4">
                    <label htmlFor="attendance" className="text-lg font-bold" style={{ marginRight: "1%" }}>Select Attendance Type:</label>
                    <select
                      id="attendance"
                      name="attendance"
                      value={selectedAttendance}
                      onChange={handleAttendanceChange} // Call handleAttendanceChange on change
                      className="border border-gray-300 rounded-lg p-2"
                    >
                      <option value="">Select Attendance Type</option>
                      <option value="live">Live</option>
                      <option value="online">Online</option>
                    </select>
                  </div>
                </div>

                <h2>Payment: NGN {paymentAmount}</h2>
                <button
                  style={{ width: "100%", backgroundColor: "#3d7c4c" }}
                  className={`${styles.btn} w-full`}
                  type="submit"
                >
                  Register
                </button>
              </div>

              <div className="text-center py-4">
                <span className="text-gray-500">
                  Already Registered? <Link className="text-red-500" to="/login">Login Now</Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
