import React from 'react';
import { Link } from 'react-router-dom';
// import './Profile.css'

function Workshop() {

  
          console.log(process.env.REACT_APP_SERVER_DOMAIN)
    
  return (
    <>
     
        <div className="culmn" style={{backgroundColor: "white"}}>
        <header id="main_menu" className="header navbar-fixed-top" style={{position: "fixed", top: "0", left: "0"}}>
            <div className="main_menu_bg">
                <div className="containerr">
                    <div className="row">
                        <div className="nave_menu">
                            <nav className="navbar navbar-default" style={{position: "fixed", top: "0", left: "0", width:"100%"}}>
                                <div className="container-fluid">
                                    <div className="navbar-header">
                                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                                            data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                                            <span className="sr-only">Toggle navigation</span>
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                        </button>
                                        <a className="navbar-brand" href="#home">
                                        <img src="assets/images/logo.png" style={{ width: '100px', height: 'auto',  borderRadius: '50%', marginRight: '10px', backgroundColor: "white" }} />
                                        </a>

                                    </div>

                                     {/* Collect the nav links, forms, and other content for toggling  */}



                                    <div  className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">

                                        <ul  className="nav navbar-nav navbar-right">
                                            <li ><a style={{fontSize: "15px"}} href="#home">HOME</a></li>
                                            <li><a style={{fontSize: "15px"}}  href="#history">ABOUT US</a></li>
                                            
                                            <li><a style={{fontSize: "15px"}}  href="#pricing">PRICING</a></li>  
                                            <li><a style={{fontSize: "15px"}}  href="https://sourceforge.net/projects/openmaint/files/latest/download">DOWNLOAD OPENMAINT</a></li>  
                                                                                  </ul>


                                    </div>

                                </div>
                            </nav>
                        </div>
                    </div>

                </div>

            </div>
        </header>
        {/* <!--End of header --> */}




        {/* <!--home Section --> */}
        <section
  id="home"
  className="home"
  style={{
    background: "url(../assets/images/builders.jpg) no-repeat center center",
    backgroundSize: "cover", // Optional: Adjust the background size as needed
  }}
>






            <div className="overlay">
                <div className="home_skew_border">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 ">
                                <div className="main_home_slider text-center">
                                    <div className="single_home_slider">
                                        <div className="main_home" >
                                            <h1 style={{marginTop: "10px", fontSize: "25px"}}>NIGERIAN INSTITUTION OF BUILDERS IN FACILITIES MANAGEMENT'S </h1>

                                            <h1 style={{marginTop: "10px", fontSize: "25px"}}>  IN<br />

                                            
                                            PARTNERSHIP WITH ALISON </h1>
                                            <div className="separator"></div>
                                            <div>
                                                 <h2  style={{fontSize: "15px", backgroundColor: "#d9a157"}} className="btn btn-lg m_t_10"> PRESENTS</h2>
                                            </div>
                                           <br/>
                                           <br/>
                                           <h2 style={styles.borderedText}>
                                                FACILITIES MANAGEMENT AND MAINTAINANCE CERTIFICATION COURSE</h2>

                                                <h2 style={{ marginTop: "40px", color: "white", fontSize: "25px" }}>LAGOS VENUE</h2>
<p style={{ marginTop: "20px", color: "white", fontSize: "30px", border: "2px solid red", padding: "10px" }}>
NISPRI GUEST HOUSE AND SUITES,
  <br />
  1 BALIKISU IYEDE ST,ABULE -IJESHA,OFF IWAYA RD LAGOS
  </p>


                                            <div className="home_btn">
                                            
                                            <h2 style={{ marginTop: "40px", color: "white", fontSize: "25px" }}>ABUJA VENUE</h2>
  <p style={{ marginTop: "20px", color: "white", fontSize: "30px", border: "2px solid red", padding: "10px" }}>
WATCH OUT!
    <br />
   
  </p>

                                       <div>
                                         <Link className='text-red-500' to="/payment">
                                        <button style={{cursor: "pointer", marginTop: "10%", backgroundColor: "#3d7c4c"}} className="btn btn-lg m_t_10">REGISTER NOW (LAGOS ATTENDEES)</button>
                                        </Link>
                                        <Link className='text-red-500' to="/abujaattendees">
                                        <button style={{cursor: "pointer", marginTop: "2%", backgroundColor: "#3d7c4c"}} className="btn btn-lg m_t_10">REGISTER NOW (ABUJA ATTENDEES)</button>
                                        </Link>
                                        <a className='text-red-500' href="https://sourceforge.net/projects/openmaint/files/latest/download">
                                        <button style={{cursor: "pointer", marginTop: "2%", backgroundColor: "#3d7c4c"}} className="btn btn-lg m_t_10">DOWNLOAD OPEN MAINTAINANCE</button>
                                        </a>
                                       </div>

                                        {/* <Link to="/login">
                                        <button  style={{cursor: "pointer", marginTop: "5%", backgroundColor: "#3d7c4c"}} className="btn btn-default">LOGIN</button>
                                        </Link> */}

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="scrooldown">
                        <a href="#history"><i className="fa fa-arrow-down"></i></a>
                    </div>
                </div>
            </div>
        </section>
        {/* <!--End of home section --> */}


        

        {/* <!-- History section --> */}
        <section style={{paddingBottom: "80px"}} id="history" className="history sections">
            <div className="container">
                <div className="row">
                    <div className="main_history">
                        <div className="col-sm-6">
                            <div className="single_history_img">
                                <img src="assets/images/stab1.png" alt="" />
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="single_history_content">
                                <div className="head_title">
                                    <h2>ABOUT US</h2>
                                </div>
                                <p style={{fontSize: "1.5rem"}} >NIBIFM is one of the arms of the NIOB  that serve as an institution for specialisation of Facilities Management Practices.
Hence, the institution was created to provide a forum for members whose training is focused on Facilities Management and train of those whose interests are also in Facility Management.
                              

</p>

                                <a href="https://nafm.org.ng/about" className="btn btn-lg" style={{backgroundColor:"#d9a157"}}>READ MORE</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--End of row --> */}
            </div>
            {/* <!--End of container --> */}
        </section>
        {/* <!--End of history --> */}


        {/* <!-- service Section --> */}
        <section id="service" className="service">
    <div className="container-fluid">

            <div className="justify-content-center align-items-center">
                <figure className="single_service single_service_img">
                    <img style={{alignItems:"center"}}
                        src="assets/images/workshop.jpeg"
                        alt=""
                    />
                </figure>
            </div>
    </div>
</section>


        {/* <!-- End of service Section --> */}

        
        {/* <!-- Pricing Section --> */}
        <section id="pricing" className="pricing">
            <div className="container">
                <div className="row">
                    <div className="main_pricing_area sections">
                        <div className="head_title text-center">
                            <h2>ATTENDANCE FEE</h2>
                            
                            <div className="separator"></div>
                        </div>
                        {/* <!-- End off Head_title --> */}

                        <div className="col-md-4 col-sm-6">
                            <div className="single_pricing">
                                <div className="pricing_head">
                                    <h3>LIVE ONLINE TRAINING</h3>
                                    <div className="pricing_price">
                                        <div className="p_r text-center">#80000</div>
                                    
                                    </div>
                                </div>

                                <div className="pricing_body">
                                    
                                <Link to="/payment" className="btn btn-md">PAY NOW</Link>
                                </div>
                            </div>
                        </div> 
                        {/* <!-- End off col-md-4 --> */}
                        <div className="col-md-4 col-sm-6">
                            <div className="single_pricing pricing_business">
                                <div className="pricing_head ">
                                    <h3>LIVE PHYSICAL TRAINING</h3>
                                    <div className="pricing_price">
                                        <div className="p_r text-center">#100,000</div>
                                     
                                    </div>
                                </div>

                                <div className="pricing_body">
                                   
                                <Link to="/payment" className="btn btn-md">PAY NOW</Link>
                                </div>
                            </div>
                        </div>
                         {/* <!-- End off col-md-4 --> */}
                        
                         {/* <!-- End off col-md-4 --> */}
                    </div>
                </div>
                {/* <!-- End off row --> */}
            </div>
            {/* <!-- End off container --> */}
        </section>
        {/* <!-- End off Pricing Section --> */}

        <section id="contact" className="contact">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="contact_contant sections">
                            <div className="head_title text-center">
                                <h2>kEEP IN TOUCH</h2>
                                <div className="subtitle">
                                    Reach out to us if you have any questions.
                                </div>
                                <div className="separator"></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="main_contact_info">
                                        <div className="row">
                                            <div className="contact_info_content padding-top-90 padding-bottom-60 p_l_r">
                                                <div className="col-sm-12">
                                                    <div className="single_contact_info">
                                                        <div className="single_info_text">
                                                            <h3>OUR ADDRESS</h3>
                                                            <h4>CORBON HOUSE,ERIC MOORE,SURULERE, LAGOS.</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="single_contact_info">
                                                        <div className="single_info_text">
                                                            <h3>CALL US</h3>
                                                            <h4> Dr Olufemi Akinsola: +234 803 547 4738 Chairman </h4>
                                                            <h4>Bldr Tunde Odunbaku: +234 803 855 7753 SECRETARY</h4>
                                                            <h4>Dr Abu Makoji: +234 706 073 2014<br/> PR0</h4>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="single_contact_info">
                                                        <div className="single_info_text">
                                                            <h3>EMAIL US</h3>
                                                            <h4> admin@nafm.org.ng</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="single_contant_left padding-top-90 padding-bottom-90">
                                        <form action="#" id="formid">
                                            <div
                                                className="col-lg-8 col-md-8 col-sm-10 col-lg-offset-2 col-md-offset-2 col-sm-offset-1">

                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" name="name"
                                                                placeholder="First Name" required="" />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <input type="email" className="form-control" name="email"
                                                                placeholder="Email" required="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" name="subject"
                                                                placeholder="Subject" required=""/>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="form-group">
                                                    <textarea className="form-control" name="message" rows="7"
                                                        placeholder="Message"></textarea>
                                                </div>

                                                <div className="">
                                                    <input type="submit" value="SEND MESSAGE" className="btn btn-lg"/>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="main_footer">
                            <div className="row">
                                <div className="col-sm-6 col-xs-12">
                                    <div className="flowus">
                                        <a href=""><i className="fa fa-facebook"></i></a>
                                        <a href=""><i className="fa fa-twitter"></i></a>
                                        <a href=""><i className="fa fa-google-plus"></i></a>
                                        <a href=""><i className="fa fa-instagram"></i></a>
                                        <a href=""><i className="fa fa-youtube"></i></a>
                                        <a href=""><i className="fa fa-dribbble"></i></a>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-xs-12">
                                    <div className="copyright_text">
                                        <p style={{visibility:"visible", color:"white"}} className=" wow fadeInRight" data-wow-duration="1s">&copy; 2023 Technosoft. All Rights
                                            Reserved</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div> 
    </>
  )
}

export default Workshop

// CSS styles
const styles = {
    borderedText: {
      position: 'relative',
      display: 'inline-block',
      border: '2px solid white',
      padding: '10px',
      animation: 'flashBorder 2s linear infinite',
      color: "white"
    },
  };
  
  // CSS animation keyframes
  const keyframes = `@keyframes flashBorder {
    0% {
      border-color: white;
    }
    50% {
      border-color: transparent;
    }
    100% {
      border-color: white;
    }
  }`;
  
  // Inject the CSS keyframes into the document
  const styleSheet = document.createElement('style');
  styleSheet.innerHTML = keyframes;
  document.head.appendChild(styleSheet);