import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useAuthStore } from '../store/store';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './Reciept.css';

function MealTag() {
  const { mealType } = useParams(); // Get the meal type from URL parameter
  const { username } = useAuthStore((state) => state.auth);
  const [userData, setUserData] = useState({
    status: '',
    mobile: '',
    firstName: '',
    lastName: '',
    createdAt: '',
    amount: '',
    profile: '',
  });

  useEffect(() => {
    if (username) {
      axios
        .get(`/api/user/${username}`)
        .then(response => {
          const { status, mobile, firstName, lastName, createdAt, amount, profile } = response.data;
          setUserData({ status, mobile, firstName, lastName, createdAt, amount, profile });
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        });
    }
  }, [username]);

  const formattedCreatedAt = new Date(userData.createdAt).toLocaleString();
  const [loader, setLoader] = useState(false);

  const downloadPDF = () => {
    const capture = document.querySelector('.actual-receipt');
    setLoader(true);
    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL('img/png');
      const doc = new jsPDF('p', 'mm', 'a6');
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
      setLoader(false);
      doc.save(`${mealType}_mealtag.pdf`);
    });
  };

  return (
    <div className="wrapper">
      <div className="receipt-box">
        <div className="actual-receipt">
          <div style={{ marginTop: '2%' }} className="receipt-organization-logo">
            <img
              style={{ borderRadius: '50%' }}
              alt="profilepic"
              src={userData.profile ? userData.profile : '../assets/images/logo.png'}
            />
          </div>

          <h5>{mealType.toUpperCase()} MEAL TAG</h5>

          <div className="colored-row first">
            <span>Payment Method</span>
            <span>Online</span>
          </div>

          <div className="data-row">
            <span className="font-weight">UserName</span>
            <span>{username}</span>
          </div>

          <div className="data-row">
            <span className="font-weight">Name</span>
            <span>{userData.firstName} {userData.lastName}</span>
          </div>

          <div className="colored-row">
            <span>Transaction Details</span>
            <span />
          </div>

          <div className="data-row border-bottom">
            <span>
              <span className="font-weight">Membership Status :</span>
            </span>
            <span>{userData.status}</span>
          </div>

          <div className="data-row border-bottom">
            <span>
              <span className="font-weight">Phone:</span>
            </span>
            <span>{userData.mobile}</span>
          </div>

          <div className="colored-row">
            <span>Thank You</span>
            <span />
          </div>
        </div>

        <div className="receipt-actions-div">
          <div className="actions-right">
            <button
              className="receipt-modal-download-button"
              onClick={downloadPDF}
              disabled={loader}
            >
              {loader ? <span>Downloading</span> : <span>Download</span>}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MealTag;
