import React, { useState, useEffect } from 'react';
import avatar from '../assets/profile.png';
import toast, { Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import { profileValidation } from '../helper/validate';
import convertToBase64 from '../helper/convert';
import { updateUser } from '../helper/helper';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthStore } from '../store/store';
import useFetch from '../hooks/fetch.hook';
import './Profile.css'

import styles from '../styles/Username.module.css';
import extend from '../styles/Profile.module.css';

export default function Profile() {
  const location = useLocation();

  // const [file, setFile] = useState();
  // const navigate = useNavigate();
  const { username } = useAuthStore((state) => state.auth);
  // const [isLoading, setIsLoading] = useState(true);
  // const [apiData, setApiData] = useState(null);
  // const [serverError, setServerError] = useState(null);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(`http://localhost:8080/api/user/${username}`);
  //       if (!response.ok) {
  //         throw new Error('Request failed');
  //       }
  //       const data = await response.json();
  //       setIsLoading(false);

  //       setApiData(data);

  //       // Redirect to Dashboard if profile is updated
  //       if (data?.isProfileUpdated && data?.isRegistrationComplete) {
  //         navigate('/dashboard');}
  //     } catch (error) {
  //       setServerError(error);
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [username, navigate]);

  const [file, setFile] = useState();
  const [{ isLoading, apiData, serverError }] = useFetch();
  const navigate = useNavigate()


  const formik = useFormik({
    initialValues: {
      firstName: apiData?.firstName || '',
      lastName: apiData?.lastName || '',
      email: apiData?.email || '',
      mobile: apiData?.mobile || '',
      address: apiData?.address || '',
      isInducted: apiData?.isInducted, // Initialize with default value
      inductionLocation: apiData?.inductionLocation ||'', // Initialize with default value
      isMember: apiData?.isMember, // Initialize with default value
      membershipNo: apiData?.membershipNo ||'', // Initialize with default value
      status: location.pathname === '/excoregistration' ? 'Exco' : apiData?.status || '',
    },
    enableReinitialize: true,
    validate: profileValidation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      if (formik.isValidating || !formik.isValid) {
        setSubmitting(false);
        return;
      }



  // Update the amount based on the selected status
  if (values.status === 'Fellow') {
    values.amount = 15000;
  } else if (values.status === 'Others') {
    values.amount = 10000;
  // } else if (values.status === 'Non-Member') {
  //   values.amount = 20000
  }

// add leading zero

  if (values.mobile && !values.mobile.startsWith('0')) {
    values.mobile = '0' + values.mobile;
  }
      values = await Object.assign(values, { profile: file || apiData?.profile || '' });
      let updatePromise = updateUser(values);
      toast
        .promise(updatePromise, {
          loading: 'Updating...',
          success: <b>Update Successfully...!</b>,
          error: <b>Could not Update!</b>,
        })
        .then(() => {
          // Redirect to Dashboard after successful update
          navigate('/dashboard');
        })
        .catch((error) => {
          // Handle error if needed
          console.error('Update error:', error);
        });
    },
  });

  const onUpload = async (e) => {
    const base64 = await convertToBase64(e.target.files[0]);
    setFile(base64);
  };

  function userLogout() {
    localStorage.removeItem('token');
    navigate('/login');
  }

  if (isLoading) return <h1 className='text-2xl font-bold'>isLoading</h1>;
  if (serverError) return <h1 className='text-xl text-red-500'>{serverError.message}</h1>;


  return (
    <div className='register'>
  <div className='container mx-auto'>
      <Toaster position='top-center' reverseOrder={false}></Toaster>

      <div className='flex justify-center items-center h-screen'>
        <div className={`${styles.glass} ${extend.glass}`} style={{ width: '45%', paddingTop: '3em' }}>
          <div className='title flex flex-col items-center'>
            <h4 className='text-5xl font-bold'>Profile</h4>
            <span className='py-4 text-xl w-2/3 text-center text-gray-500'>
              Fill in your information to access conference resources and materials
            </span>
          </div>

          <form className='py-1' onSubmit={formik.handleSubmit}>
            <div className='profile flex justify-center py-4'>
              <label htmlFor='profile'>
                <img src={apiData?.profile || file || avatar} className={`${styles.profile_img} ${extend.profile_img}`} alt='avatar' />
              </label>

              <input onChange={onUpload} type='file' id='profile' name='profile' />
              <p>Please upload image less than 70kb</p>
            </div>


            <div className='textbox flex flex-col items-center gap-6'>
              <div className='name flex w-3/4 gap-10'>
                <input {...formik.getFieldProps('firstName')} className={`${styles.textbox} ${extend.textbox}`} type='text' placeholder='FirstName' required/>
                <input {...formik.getFieldProps('lastName')} className={`${styles.textbox} ${extend.textbox}`} type='text' placeholder='LastName' required/>
              </div>

              <div className='name flex w-3/4 gap-10'>
                <input {...formik.getFieldProps('mobile')} className={`${styles.textbox} ${extend.textbox}`} type='text' placeholder='Mobile No.' required/>
                <input {...formik.getFieldProps('email')} className={`${styles.textbox} ${extend.textbox}`} type='text' placeholder='Email*' required readOnly/>
              </div>
              {/* <div
                style={{
                  textAlign: "center",
                  color: "white",
                  fontSize: "20px",
                }}
              >
                <input
                  {...formik.getFieldProps("isInducted")}
                  type="checkbox"
                  style={{ marginRight: "10px" }}
                />
                <label htmlFor="isInducted" style={{ flex: "1" }}>
                  Have you been inducted?
                </label>
  
                {formik.values.isInducted && (
                  <>
                    <input
                      {...formik.getFieldProps("inductionLocation")}
                      type="text"
                      placeholder="Enter Induction Location"
                      className={styles.textbox}
                      required
                      style={{color:"black"}}
                    />
                    {formik.touched.inductionLocation &&
                    formik.errors.inductionLocation ? (
                      <div className="error">
                        {formik.errors.inductionLocation}
                      </div>
                    ) : null}
                  </>
                )}
              </div> */}


              {/* ask if they are members */}

              {/* <div
                style={{
                  textAlign: "center",
                  color: "white",
                  fontSize: "20px",
                }}
              >
                <input
                  {...formik.getFieldProps("isMember")}
                  type="checkbox"
                  style={{ marginRight: "10px" }}
                />
                <label htmlFor="isMember" style={{ flex: "1" }}>
                  Are you a member?
                </label>
  
                {formik.values.isMember && (
                  <>
                    <input
                      {...formik.getFieldProps("membershipNo")}
                      type="text"
                      placeholder="Enter Membership No"
                      className={styles.textbox}
                      required
                      style={{color:"black"}}
                    />
                    {formik.touched.membershipNo &&
                    formik.errors.membershipNo ? (
                      <div className="error">
                        {formik.errors.membershipNo}
                      </div>
                    ) : null}
                  </>
                )}
              </div> */}


              <div style={{ textAlign: 'center', color: 'white', fontSize: '20px' }}>
    {/* <label htmlFor='status' style={{ flex: '1' }}>
      Select Status:
    </label> */}
    
    {!apiData?.status && (
      <select {...formik.getFieldProps('status')} className={styles.textbox} style={{ color: 'black', fontSize: '20px', marginBottom: "10px" }} required>
        <option value=''>-- Select Status --</option>
        <option value='Fellow'>Fellow</option>
        <option value='Member'>Member</option>
        <option value='Non Member'>Non Member</option>
      </select>
    )}
    
    {apiData?.status && (
      <p style={{fontSize:"25px"}}>Current Status: <span style={{color: "black"}}>{apiData.status}</span></p>
    )}
  </div>
  

              {/* <select style={{marrginTop: "2%", marginBottom: "2%", width: "100%"}}
  {...formik.getFieldProps('status')}
  className={`${styles.textbox} ${extend.textbox}`}
>
  <option value="">Select Status</option>
  <option value="Fellow">Fellow</option>
  <option value="Member">Member</option>
  <option value="Non Member">Non Member</option>
</select>
{formik.touched.status && formik.errors.status ? (
  <div className="error">{formik.errors.status}</div>
) : null} */}

  
              <input {...formik.getFieldProps('address')} className={`${styles.textbox} ${extend.textbox}`} type='text' placeholder='Address' style={{ marginBottom: "10px" }} required />
              <button style={{width: "100%",  backgroundColor: "#3d7c4c"}} className={`${styles.btn} w-full`} type='submit' disabled={formik.isSubmitting}>
  Update
</button>

            </div>

            <div style={{fontSize: "20px"}} className='text-center py-4'>
              <span className='text-gray-500'>
                Come back later? <button style={{backgroundColor: "red", color: "white", border: "none", padding: "0 10px 0 10px"}} onClick={userLogout} className='text-red-500' to='/'>
                  Logout
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
    </div>
  
  );
}
