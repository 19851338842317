import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Registered = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Fetch all users
    axios
      .get('/api/users')
      .then((response) => {
        console.log('API Response:', response.data); // Check the response data structure
        // Find the index of the username 'devon'
        const devonIndex = response.data.findIndex(user => user.username === 'OLATUNJI');
        
        if (devonIndex !== -1) {
          // Filter users to start populating from the username 'devon'
          const filteredUsers = response.data.slice(devonIndex);
          setUsers(filteredUsers);
        }
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      });
  }, []);

  console.log('Users:', users); // Check the users state

  return (
    <div style={{ color: 'white' }}>
      <h2 style={{ textAlign: 'center' }}>Registered Users List</h2>
      <div style={{ overflowX: 'auto' }}>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid black', padding: '8px' }}>#</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Name</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Email</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Status</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Mobile</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Amount Paid</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>Is Inducted?</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user.username}>
                <td style={{ border: '1px solid black', padding: '8px' }}>{index + 1}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>{user.username}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>{user.email}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>{user.status}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>{user.mobile}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>{user.amount}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>{user.isInducted ? 'Yes' : 'No'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Registered;
