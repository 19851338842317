import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import avatar from '../assets/profile.png';
import toast, { Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import { usernameValidate } from '../helper/validate';
import { useAuthStore } from '../store/store';

import styles from '../styles/Username.module.css';

export default function Username() {
  const navigate = useNavigate();
  const setUsername = useAuthStore(state => state.setUsername);
  const [isChecking, setIsChecking] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: ''
    },
    validate: usernameValidate,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async values => {
      setIsChecking(true);
      setTimeout(async () => {
        try {
          // Simulate username checking
          await new Promise(resolve => setTimeout(resolve, 2000));
          setUsername(values.username);
          navigate('/password');
        } catch (error) {
          console.error(error);
        } finally {
          setIsChecking(false);
        }
      }, 1000);
    }
  });

  return (
    <div className='register'>
      <div className="container mx-auto">
        <Toaster position='top-center' reverseOrder={false}></Toaster>
        <div className='flex justify-center items-center h-screen'>
          <div className={styles.glass}>
            <div className="title flex flex-col items-center">
              <h3 style={{ color: "black", marginBottom: "10%" }} className='text-5xl font-bold lowercase'>Hello Again!</h3>
              <span className='py-4 text-xl w-2/3 text-center text-gray-500'>
                Explore More by connecting with us.
              </span>
            </div>
            <form className='py-1' onSubmit={formik.handleSubmit}>
              <div className='profile flex justify-center py-4'>
                <img src={avatar} className={styles.profile_img} alt="avatar" />
              </div>
              <div className="textbox flex flex-col items-center gap-6">
                <input {...formik.getFieldProps('username')} className={styles.textbox} type="text" placeholder='Username' />
                <button style={{ width: "100%", backgroundColor: "#3d7c4c" }} className={styles.btn} type='submit' disabled={isChecking}>
                  {isChecking ? 'Checking...' : "Let's Go"}
                </button>
              </div>
              <div className="text-center py-4">
                <span className='text-gray-500'>Not a Member <Link className='text-red-500' to="/payment">Register Now</Link></span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
