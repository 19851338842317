
import { Navigate } from "react-router-dom";
import { useAuthStore } from "../store/store";
import Payment from "../components/Payment";
import handlePaymentSuccess from '../components/Payment'
export const AuthorizeUser = ({ children }) => {
    const token = localStorage.getItem('token');

    if(!token){
        return <Navigate to={'/'} replace={true}></Navigate>
    }

    return children;
}


export const ProtectRoute = ({ children }) => {
    const username = useAuthStore.getState().auth.username;
    if(!username){
        return <Navigate to={'/login'} replace={true}></Navigate>
    }

    // if (handlePaymentSuccess) {
    //     return <Navigate to="/register" replace={true} />;
    //   }
    return children;
}

// export const ProtectRoute = ({ children }) => {
//     const { username, isProfileUpdated } = useAuthStore((state) => state.auth);
  
//     if (!username) {
//       return <Navigate to={'/'} replace={true} />;
//     }
  
//     if (username && isProfileUpdated) {
//       return <Navigate to={'/dashboard'} replace={true} />;
//     }
  
//     return children;
//   };