import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import avatar from '../assets/profile.png';
import toast, { Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import { registerValidation } from '../helper/validate';
import convertToBase64 from '../helper/convert';
import { registerUser } from '../helper/helper';

import styles from '../styles/Profile.module.css';
import extend from '../styles/Profile.module.css';
import './Profile.css';

export default function Register() {
  const navigate = useNavigate();
  const location = useLocation();
  const paymentAmount = location.state?.paymentAmount || 0; // Access paymentAmount from location state
  const status = location.state?.status; // Access status from location state
  const membershipNo = location.state?.membershipNo;
  const firstNameFromState = location.state?.firstName; // Access firstName from location state
  const lastNameFromState = location.state?.lastName; // Access lastName from location state

  const [file, setFile] = useState();

  const formik = useFormik({
    initialValues: {
      email: '',
      username: '',
      password: '',
      amount: paymentAmount / 100, // Include paymentAmount in formik initialValues
      status: status,
      membershipNo: membershipNo || '',
      firstName: firstNameFromState || '',
      lastName: lastNameFromState || '',
      isInducted: false, // Initialize with default value
      inductionLocation: '', // Initialize with default value
      cook: ''
    },
    validate: registerValidation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async values => {
      console.log('Submitting form with values:', values); // Debug log

      if (paymentAmount === 0) {
        alert("You have to pay before you can register");
        navigate('/payment');
        return;
      }

      values = await Object.assign(values, {
        profile: file || '',
        amount: paymentAmount / 100,
        status: location.state?.status, // Add null check
        membershipNo: membershipNo || '',
        firstName: values.firstName || '', // Ensure firstName is included
        lastName: values.lastName || '' // Ensure lastName is included
      });

      const onRegistrationSuccess = () => {
        localStorage.setItem('isRegistrationComplete', 'true');
      };

      const registerPromise = registerUser(values);
      toast.promise(registerPromise, {
        loading: 'Creating Account...',
        success: <b>Registered Successfully...!</b>,
        error: <b>Could not Register.</b>
      })
      .then(onRegistrationSuccess)
      .then(() => navigate('/login'));
    }
  });

  const onUpload = async e => {
    const base64 = await convertToBase64(e.target.files[0]);
    setFile(base64);
    formik.setFieldValue('profile', base64); // Set the value of the profile field
    formik.setFieldTouched('profile', true); // Mark the profile field as touched

    if (base64) {
      formik.handleSubmit(); // Trigger form submission
    }
  };

  return (
    <div className='register'>
      <div className="container mx-auto">
        <Toaster position="top-center" reverseOrder={false}></Toaster>

        <div className="flex justify-center items-center h-screen">
          <div
            className={`${styles.glass} ${extend.glass} w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3`}
            style={{ paddingTop: "3em", width: '100%' }}
          >
            <div className="title flex flex-col items-center">
              <h4 className="text-5xl font-bold">Continue Registration...</h4>
              <span className="py-4 text-xl w-2/3 text-center text-gray-500">
                We look forward to seeing you
              </span>
            </div>

            <form className="py-1" onSubmit={formik.handleSubmit}>
              <div className="profile flex justify-center py-4">
                <label htmlFor="profile">
                  <img
                    src={file || avatar}
                    className={styles.profile_img}
                    alt="avatar"
                  />
                </label>
              </div>
              <div className="textbox flex flex-col items-center gap-6">
                <input
                  {...formik.getFieldProps("email")}
                  className={styles.textbox}
                  type="text"
                  placeholder="Email*"
                />
                <input
                  {...formik.getFieldProps("username")}
                  className={styles.textbox}
                  type="text"
                  placeholder="Username*"
                  style={{ '::placeholder': { color: 'black' } }}
                />
                <input
                  {...formik.getFieldProps("password")}
                  className={styles.textbox}
                  type="text"
                  placeholder="Password*"
                />
                <input
                  {...formik.getFieldProps("firstName")}
                  className={styles.textbox}
                  type="text"
                  placeholder="First Name"
                  value={formik.values.firstName}
                  readOnly={!!firstNameFromState} // Make the field read-only if firstName is provided from location state
                  required // Make the field required
                />
                <input
                  {...formik.getFieldProps("lastName")}
                  className={styles.textbox}
                  type="text"
                  placeholder="Last Name"
                  value={formik.values.lastName}
                  readOnly={!!lastNameFromState} // Make the field read-only if lastName is provided from location state
                  required // Make the field required
                />
                <div style={{ textAlign: "center", color: "white", fontSize: "20px" }}>
                  <input
                    {...formik.getFieldProps("isInducted")}
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                  />
                  <label htmlFor="isInducted" style={{ flex: "1" }}>
                    Have you been inducted?
                  </label>
                  {formik.values.isInducted && (
                    <>
                      <input
                        {...formik.getFieldProps("inductionLocation")}
                        type="text"
                        placeholder="Enter Induction Location"
                        className={styles.textbox}
                        required
                        style={{ color: "black" }}
                      />
                      {formik.touched.inductionLocation && formik.errors.inductionLocation ? (
                        <div className="error">
                          {formik.errors.inductionLocation}
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
                <h2>Status: {status}</h2>
                {/* {membershipNo && <h2>Membership Number: {membershipNo}</h2>} */}
                <h2>Payment: {paymentAmount / 100}</h2>
                <button
                  style={{ width: "100%", backgroundColor: "#3d7c4c" }}
                  className={`${styles.btn} w-full`}
                  type="submit"
                >
                  Register

                </button>
              </div>

              <div className="text-center py-4">
                <span className="text-gray-500">
                  Already Register? <Link className="text-red-500" to="/login">Login Now</Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
