import React from 'react';
import { Link } from 'react-router-dom';
// import './Profile.css'

function Home() {

  
          console.log(process.env.REACT_APP_SERVER_DOMAIN)
    
  return (
    <>
     
        <div className="culmn" style={{backgroundColor: "white"}}>
        <header id="main_menu" className="header navbar-fixed-top" style={{position: "fixed", top: "0", left: "0"}}>
            <div className="main_menu_bg">
                <div className="containerr">
                    <div className="row">
                        <div className="nave_menu">
                            <nav className="navbar navbar-default" style={{position: "fixed", top: "0", left: "0", width:"100%"}}>
                                <div className="container-fluid">
                                    <div className="navbar-header">
                                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                                            data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                                            <span className="sr-only">Toggle navigation</span>
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                        </button>
                                        <a className="navbar-brand" href="#home">
                                        <img src="assets/images/logo.png" style={{ width: '100px', height: 'auto',  borderRadius: '50%', marginRight: '10px', backgroundColor: "white" }} />
                                        </a>

                                    </div>

                                     {/* Collect the nav links, forms, and other content for toggling  */}



                                    <div  className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">

                                        <ul  className="nav navbar-nav navbar-right">
                                            <li ><a style={{fontSize: "15px"}} href="#home">HOME</a></li>
                                            <li><a style={{fontSize: "15px"}}  href="#history">ABOUT US</a></li>
                                            
                                            <li><a style={{fontSize: "15px"}}  href="#pricing">PRICING</a></li>  
                                            <li>
                                            <Link style={{ fontSize: "15px" }} to="/login">LOGIN</Link>
                                            </li>                                        </ul>


                                    </div>

                                </div>
                            </nav>
                        </div>
                    </div>

                </div>

            </div>
        </header>
        {/* <!--End of header --> */}




        {/* <!--home Section --> */}
        <section id="home" className="home" >
            <div className="overlay">
                <div className="home_skew_border">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 ">
                                <div className="main_home_slider text-center">
                                    <div className="single_home_slider">
                                        <div className="main_home" >
                                            <h3>We welcome you to the...</h3>
                                            <h1 style={{marginTop: "10px", fontSize: "25px"}}>NIGERIAN INSTITUTION OF BUILDERS IN FACILITIES MANAGEMENT'S </h1>

                                            <h1 style={{marginTop: "10px", fontSize: "25px"}}>3RD ANNUAL CONFERENCE & GENERAL MEETING- ANAMBRA STATE, 2024 </h1>
                                            <div className="separator"></div>
                                            <div>
                                                 <h2  style={{fontSize: "15px", backgroundColor: "#d9a157"}} className="btn btn-lg m_t_10"> THEME</h2>
                                            </div>
                                           <br/>
                                           <br/>
                                           <h2 style={styles.borderedText}>
                                                SUSTAINABLE PRACTICES IN FACILITIES MANAGEMENT!</h2>

                                                <h2 style={{ marginTop: "40px", color: "white", fontSize: "25px" }}>VENUE</h2>
<p style={{ marginTop: "20px", color: "white", fontSize: "30px", border: "2px solid red", padding: "10px" }}>
 Nnamdi Azikiwe University, Awka,
  <br />
  ASUU NAU Secretariat Hall, Along Enugu-Onitsha Expressway,   Awka, Anambra State. 
  <br/>
Immediately after Stanel filling station.
</p>


                                            <div className="home_btn">
                                            
                                            <h2 style={{ marginTop: "40px", color: "white", fontSize: "25px" }}>DATE &amp; TIME</h2>
  <p style={{ marginTop: "20px", color: "white", fontSize: "30px", border: "2px solid red", padding: "10px" }}>
    Monday, 19th August 2024.
    <br/> TIME: 
    <br/>9AM-1PM
    <br/>2PM- 6PM
    <br/> 7PM-9PM 
  </p>

                                       <div>
                                         {/* <Link className='text-red-500' to="/payment">
                                        <button style={{cursor: "pointer", marginTop: "10%", backgroundColor: "#3d7c4c"}} className="btn btn-lg m_t_10">REGISTER NOW</button>
                                        </Link> */}
                                        {/* <Link className='text-red-500' to="/payment">
                                        <button style={{cursor: "pointer", marginTop: "10%", backgroundColor: "#3d7c4c"}} className="btn btn-lg m_t_10">REGISTER NOW</button>
                                        </Link> */}
                                       </div>

                                        {/* <Link to="/login">
                                        <button  style={{cursor: "pointer", marginTop: "5%", backgroundColor: "#3d7c4c"}} className="btn btn-default">LOGIN</button>
                                        </Link> */}

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="scrooldown">
                        <a href="#history"><i className="fa fa-arrow-down"></i></a>
                    </div>
                </div>
            </div>
        </section>
        {/* <!--End of home section --> */}


        

        {/* <!-- History section --> */}
        <section style={{paddingBottom: "80px"}} id="history" className="history sections">
            <div className="container">
                <div className="row">
                    <div className="main_history">
                        <div className="col-sm-6">
                            <div className="single_history_img">
                                <img src="assets/images/stab1.png" alt="" />
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="single_history_content">
                                <div className="head_title">
                                    <h2>ABOUT US</h2>
                                </div>
                                <p style={{fontSize: "1.5rem"}} >NIBIFM is one of the arms of the NIOB  that serve as an institution for specialisation of Facilities Management Practices.
Hence, the institution was created to provide a forum for members whose training is focused on Facilities Management and train of those whose interests are also in Facility Management.
                              

</p>

                                <a href="https://nafm.org.ng/about" className="btn btn-lg" style={{backgroundColor:"#d9a157"}}>READ MORE</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--End of row --> */}
            </div>
            {/* <!--End of container --> */}
        </section>
        {/* <!--End of history --> */}


        {/* <!-- service Section --> */}
        <section   id="service" className="service">
            <div className="container-fluid">
                <div className="row">
                    <div className="main_service">
                        <div className="col-md-6 col-sm-12 no-padding">

                            <div style={{ backgroundColor: "#3d7c4c", color:"white"}} className="single_service single_service_text text-right">
                                <div className="head_title">
                                    <h2>SPEAKERS</h2>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 col-sm-10 col-xs-10 margin-bottom-60">
                                        <div className="row">

                                            <div className="col-sm-10 col-sm-offset-1 col-xs-9 col-xs-offset-1">
                                                <article className="single_service_right_text">
                                                    <h4 style={{color:"white"}}>PROF. BALA KABIR, FNIOB</h4>
                                                    <p  style={{color:"white"}}>Vice chancellor, Abu, Zaria.
                                                    </p>
                                                </article>
                                            </div>
                                            <div className="col-sm-1 col-xs-1">
                                                <figure className="single_service_icon">
                                                    <i className="fa fa-circle"></i>
                                                </figure>
                                                
                                                {/* <!-- End of figure --> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End of col-sm-12 --> */}

                                    <div className="col-md-12 col-sm-10 col-xs-10 margin-bottom-60">
                                        <div className="row">

                                            <div className="col-sm-10 col-sm-offset-1 col-xs-9 col-xs-offset-1">
                                                <article className="single_service_right_text">
                                                    <h4 style={{color:"white"}}>PROF. OLUMIDE ADENUGA</h4>
                                                    <p  style={{color:"white"}}>University of Lagos
                                                       
                                                    </p>
                                                </article>
                                            </div>
                                            <div className="col-sm-1 col-xs-1">
                                                <figure className="single_service_icon">
                                                    <i className="fa fa-circle"></i>
                                                </figure>
                                                {/* <!-- End of figure --> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End of col-sm-12 --> */}

                                    <div className="col-md-12 col-sm-10 col-xs-10 margin-bottom-60">
                                        <div className="row">

                                            <div
                                                className="col-sm-10 col-sm-offset-1 col-xs-9 col-xs-offset-1 margin-bottom-20">
                                                <article className="single_service_right_text">
                                                    <h4 style={{color:"white"}}>BLDR. `(DR.)` SAMSON A. OPALUWAH, FNIOB, FNIBIFM</h4>
                                                    <p  style={{color:"white"}}>Chairman, CORBON.
                                                          
                                                    </p>
                                                </article>
                                            </div>
                                            <div className="col-sm-1 col-xs-1">
                                                <figure className="single_service_icon">
                                                    <i className="fa fa-circle"></i>
                                                </figure>
                                                {/* <!-- End of figure --> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End of col-sm-12 --> */}

                                </div>
                            </div>
                        </div>
                        {/* <!-- End of col-sm-6 --> */}

                        <div className="col-md-6 col-sm-12 no-padding">
                            <figure className="single_service single_service_img">
                                <div className="overlay-img"></div>
                                <img src="assets/images/servicerightimg.jpg" alt="" />
                            </figure>
                            {/* <!-- End of figure --> */}
                        </div>
                        {/* <!-- End of col-sm-6 --> */}

                    </div>
                </div>
                {/* <!-- End of row --> */}
            </div>
            {/* <!-- End of Container-fluid --> */}
        </section>
        {/* <!-- End of service Section --> */}


        <section  id="portfolio" className="portfolio sections">
            <div className="container-fluid">
                <div className="row">
                    <div className="main_portfolio">
                        <div className="col-sm-12">
                            <div className="head_title text-center">
                                <h2>HIGHLIGHT OF EVENT SPEAKERS</h2>
                            </div>
                        </div>

                        <div style={{clear:"both"}}></div>
                        <div id="portfoliowork">
                            <div className="single_portfolio tile scale-anm bcards photo">
                                <img src="assets/images/pf2.jpg" alt="" />
                                <a href="assets/images/pf2.jpg" className="portfolio-img">
                                <div class="grid_item_overlay">
                                        <div class="separator4"></div>
                                        <h3>Bldr Dr. Samson Ameh Opaluwah fnse,fniob,fnibifm</h3>
                                        
                                    </div>
                                </a>
                            </div>
                            <div className="single_portfolio tile scale-anm web video">
                                <img src="assets/images/pf.jpg" alt="" />
                                <a href="assets/images/pf.jpg" className="portfolio-img">
                                <div class="grid_item_overlay">
                                        <div class="separator4"></div>
                                        <h3>Bldr(Mrs) Titilayo Oladogbon, Director, Ministry of Housing, Ogun State.</h3>
                                    </div>
                                </a>
                            </div>
                            <div className="single_portfolio tile scale-anm web photo">
                                <img src="assets/images/pf4.jpg" alt="" />
                                <a href="assets/images/pf4.jpg" className="portfolio-img">
                                <div class="grid_item_overlay">
                                        <div class="separator4"></div>
                                        <h3>Bldr(Dr)Olufemi E.Akinsola fniob,fnibifm, National Chairman, HOST</h3>
                                        
                                    </div>
                                </a>
                            </div>
                    

                            <div className="single_portfolio tile scale-anm flyers video design">
                                <a href="assets/images/pf6.jpg" className="portfolio-img">
                                <div class="video-wrapper">
                                <video src="assets/images/video.mp4" controls autoplay muted loop>
  Sorry, your browser doesn't support embedded videos.
</video>
            </div>
                                </a>
                            </div>
                          
                        </div>

                        <div style={{clear:"both"}}></div>
                    </div>
                </div>
            </div>
            {/* <!-- End off container --> */}
        </section>
         {/* <!-- End off Work Section --> */}



     

        
        {/* <!-- Pricing Section --> */}
        <section id="pricing" className="pricing">
            <div className="container">
                <div className="row">
                    <div className="main_pricing_area sections">
                        <div className="head_title text-center">
                            <h2>ATTENDANCE FEE</h2>
                            
                            <div className="separator"></div>
                        </div>
                        {/* <!-- End off Head_title --> */}

                        <div className="col-md-4 col-sm-6">
                            <div className="single_pricing">
                                <div className="pricing_head">
                                    <h3>FELLOWS</h3>
                                    <div className="pricing_price">
                                        <div className="p_r text-center">#15000</div>
                                    
                                    </div>
                                </div>

                                <div className="pricing_body">
                                    
                                <Link to="/payment" className="btn btn-md">PAY NOW</Link>
                                </div>
                            </div>
                        </div> 
                        {/* <!-- End off col-md-4 --> */}
                        <div className="col-md-4 col-sm-6">
                            <div className="single_pricing pricing_business">
                                <div className="pricing_head ">
                                    <h3>Others</h3>
                                    <div className="pricing_price">
                                        <div className="p_r text-center">#10,000</div>
                                     
                                    </div>
                                </div>

                                <div className="pricing_body">
                                   
                                <Link to="/payment" className="btn btn-md">PAY NOW</Link>
                                </div>
                            </div>
                        </div>
                         {/* <!-- End off col-md-4 --> */}
                        {/* <div className="col-md-4 col-sm-6">
                            <div className="single_pricing">
                                <div className="pricing_head">
                                    <h3>NON-MEMBERS</h3>
                                    <div className="pricing_price">
                                        <div className="p_r text-center">#20,000</div>
                                    </div>
                                </div>

                                <div className="pricing_body">
                                 
                                <Link to="/payment" className="btn btn-md">PAY NOW</Link>
                                </div>
                            </div>
                        </div> */}
                         {/* <!-- End off col-md-4 --> */}
                    </div>
                </div>
                {/* <!-- End off row --> */}
            </div>
            {/* <!-- End off container --> */}
        </section>
        {/* <!-- End off Pricing Section --> */}

        <section id="contact" className="contact">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="contact_contant sections">
                            <div className="head_title text-center">
                                <h2>kEEP IN TOUCH</h2>
                                <div className="subtitle">
                                    Reach out to us if you have any questions.
                                </div>
                                <div className="separator"></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="main_contact_info">
                                        <div className="row">
                                            <div className="contact_info_content padding-top-90 padding-bottom-60 p_l_r">
                                                <div className="col-sm-12">
                                                    <div className="single_contact_info">
                                                        <div className="single_info_text">
                                                            <h3>OUR ADDRESS</h3>
                                                            <h4>CORBON HOUSE,ERIC MOORE,SURULERE, LAGOS.</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="single_contact_info">
                                                        <div className="single_info_text">
                                                            <h3>CALL US</h3>
                                                            <h4> Dr Olufemi Akinsola: +234 803 547 4738 Chairman </h4>
                                                            <h4>Bldr Tunde Odunbaku: +234 803 855 7753 SECRETARY</h4>
                                                            <h4>Dr Abu Makoji: +234 706 073 2014<br/> PR0</h4>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="single_contact_info">
                                                        <div className="single_info_text">
                                                            <h3>EMAIL US</h3>
                                                            <h4> admin@nafm.org.ng</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="single_contant_left padding-top-90 padding-bottom-90">
                                        <form action="#" id="formid">
                                            <div
                                                className="col-lg-8 col-md-8 col-sm-10 col-lg-offset-2 col-md-offset-2 col-sm-offset-1">

                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" name="name"
                                                                placeholder="First Name" required="" />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <input type="email" className="form-control" name="email"
                                                                placeholder="Email" required="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" name="subject"
                                                                placeholder="Subject" required=""/>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="form-group">
                                                    <textarea className="form-control" name="message" rows="7"
                                                        placeholder="Message"></textarea>
                                                </div>

                                                <div className="">
                                                    <input type="submit" value="SEND MESSAGE" className="btn btn-lg"/>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="main_footer">
                            <div className="row">
                                <div className="col-sm-6 col-xs-12">
                                    <div className="flowus">
                                        <a href=""><i className="fa fa-facebook"></i></a>
                                        <a href=""><i className="fa fa-twitter"></i></a>
                                        <a href=""><i className="fa fa-google-plus"></i></a>
                                        <a href=""><i className="fa fa-instagram"></i></a>
                                        <a href=""><i className="fa fa-youtube"></i></a>
                                        <a href=""><i className="fa fa-dribbble"></i></a>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-xs-12">
                                    <div className="copyright_text">
                                        <p style={{visibility:"visible", color:"white"}} className=" wow fadeInRight" data-wow-duration="1s">&copy; 2023 Technosoft. All Rights
                                            Reserved</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div> 
    </>
  )
}

export default Home

// CSS styles
const styles = {
    borderedText: {
      position: 'relative',
      display: 'inline-block',
      border: '2px solid white',
      padding: '10px',
      animation: 'flashBorder 2s linear infinite',
      color: "white"
    },
  };
  
  // CSS animation keyframes
  const keyframes = `@keyframes flashBorder {
    0% {
      border-color: white;
    }
    50% {
      border-color: transparent;
    }
    100% {
      border-color: white;
    }
  }`;
  
  // Inject the CSS keyframes into the document
  const styleSheet = document.createElement('style');
  styleSheet.innerHTML = keyframes;
  document.head.appendChild(styleSheet);