import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthStore } from '../store/store';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import './Certificate.css';

function Certificate() {
  const { username } = useAuthStore((state) => state.auth);
  const [userData, setUserData] = useState({
    status: '',
    mobile: '',
  });

  useEffect(() => {
    // Fetch user data from the database
    axios
      .get(`/api/user/${username}`)
      .then((response) => {
        const { status, mobile, firstName, lastName, createdAt, amount } = response.data;
        setUserData({ status, mobile, firstName, lastName, createdAt, amount });
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
  }, []);



  const [loader, setLoader] = useState(false);

  const downloadPDF = () => {
    const capture = document.querySelector('.certificate-container'); // Select the correct class
    setLoader(true);
    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL('image/png'); // Correct typo: 'img' to 'image'
      const doc = new jsPDF({
        orientation: '1', // Set to landscape
        unit: 'mm',
        format: [279.4, 215.9] // Certificate size in mm (11 x 8.5 inches)
      });
    const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
      setLoader(false);
      doc.save(`${userData.firstName} certificate.pdf`); // Correct typo: 'receipt' to 'receipt'
    });
  };

  const capitalizeFirstLetter = (name) => {
    if (!name) {
      return ''; // Return an empty string if name is not defined
    }

    return name.toLowerCase().replace(/(?:^|\s)\S/g, function (letter) {
      return letter.toUpperCase();
    });
  };
  
  return (
    <div>
    <div className="wrapper">
        <div className="certificate-container">
            <img className="certificate-image" src="/assets/images/certificate.png" alt="Certificate" />
            <div className="dynamic-content">
                <p className="user-name">{userData.firstName && userData.lastName
            ? capitalizeFirstLetter(userData.firstName) + ' ' + capitalizeFirstLetter(userData.lastName)
            : 'Loading...'}</p>
                {/* Add more text or styling as needed */}
            </div>
        </div>
        
        </div>
        <div className="download-button">
            <button
                className="receipt-modal-download-button"
                onClick={downloadPDF}
                disabled={!(loader===false)}
            >
                {loader ? <span>Downloading</span> : <span className='downloadText'>Download</span>}
            </button>
        </div>
        </div>
);

}

export default Certificate;
