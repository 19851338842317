import create from 'zustand';

export const useAuthStore = create((set) => ({
  auth: {
    username: '',
    status: '',
    mobile: '',
    active: false,
  },
  setUsername: (name) => set((state) => ({ auth: { ...state.auth, username: name } })),
  setStatus: (status) => set((state) => ({ auth: { ...state.auth, status } })),
  setMobile: (mobile) => set((state) => ({ auth: { ...state.auth, mobile } })),
  setActive: (active) => set((state) => ({ auth: { ...state.auth, active } })),
}));
