import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Worksuccessful.css'; // Create a CSS module for component-specific styling

function Worksuccessful() {
  return (
    <div className='successContainer'>
      <img src='assets/images/success.jpg' alt="Success" className='successImage'/>
      <h2 className='successMessage'> You have Successsfully Registered for the workshop!</h2>
      <p className='successText'>Thank you for registering, a receipt has been sent to your email</p>
      <Link to="/workshop" className='loginLink'>Go back</Link>
    </div>
  );
}

export default Worksuccessful;
