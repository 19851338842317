import React, { useState } from 'react';
import { PaystackButton } from 'react-paystack';
import { useNavigate, useLocation } from 'react-router-dom';
// import './Profile.css'
import './Payment.css'


const AbujaPayment = () => {

  const publicKey = "pk_live_67987fd04978d69fd39802201ed9a208505a2f27"

  
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedOption, setSelectedOption] = useState('membership');
  const [membershipNo, setMembershipNo] = useState('Abuja'); // Hardcoded membership number
  

  const navigate = useNavigate(); // Create a navigate function
  const location = useLocation();
  const [paymentAmount, setPaymentAmount] = useState(0); // Add paymentAmount state

  const resetForm = () => {
    setEmail('');
    setName('');
    setPhone('');
    setMembershipNo('');

  };

  const handlePaymentSuccess = ({ reference }) => {
    alert(`Your purchase was successful! Transaction reference: ${reference}`);
    resetForm();
    
    let status = '';
    if (selectedOption === 'members') {
      status = 'Members';
    } else if (selectedOption === 'fellow') {
      status = 'Fellows';
    } else if (selectedOption === 'nonmembers') {
      status = 'Non-Members';
    }
    
    navigate('/workshopregistration', { state: { paymentAmount, status, membershipNo } });
  };
  

  const handlePaymentClose = () => {
    alert("Hey, are you sure you want to cancel?");
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setPaymentAmount(getAmount(option));
  };

  const getAmount = (option) => {
    switch (option) {
      case 'members':
        return 10000000 * 0.9; // Apply 10% discount
      case 'fellow':
        return 8000000 * 0.9; // Apply 10% discount
      default:
        return 0;
    }
  };
  
  const componentProps = {
    email,
    amount: paymentAmount, // Set the paymentAmount as the amount
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: 'Pay Now',
    onSuccess: handlePaymentSuccess,
    onClose: handlePaymentClose,
  };

  return (
<div className="bg-gray-900 min-h-screen flex items-center justify-center payment" style={{ color: "black", alignItems: "center", justifyContent: "center", width: "100%" }}>
      <div className="bg-white rounded-lg p-8 mx-4 md:mx-auto md:max-w-md">
        <div className="text-center">
          <img
            className="w-32 h-32 mx-auto mb-4"
            src="/assets/images/stab1.png"
            alt="product"
            style={{marginBottom: "2%", borderRadius: "50%"}}
          />
          
          <h2 className="text-2xl font-bold mb-4">Nibfm Workshop Payment for ABUJA ATTENDEES</h2>
          <p style={{fontSize: "2rem"}}>Early bird gets a 10% discount.</p>
          <p className="text-xl" style={{color: "Black", fontSize: "3rem", margin:"4%"}}><span style={{marginBottom: "2%"}}> You are about to pay <br/></span><span  style={{marginTop: "5%", color:"green"}}> <br/> NGN {componentProps.amount / 100}</span> </p>
        </div>
        <div className="payment-options mb-6 flex flex-wrap flex items-center justify-center" style={{marginLeft: "30%", marginBottom: "2%", fontSize: "2rem"}}>
  <label className="flex items-center mb-2 mr-4" style={{ marginRight: "2%" }}>
    <input
      type="radio"
      name="paymentOption"
      value="fellow"
      checked={selectedOption === "fellow"}
      onChange={() => handleOptionChange("fellow")}
    />
    <span className="text-lg font-bold text-purple-600" style={{ marginLeft: "5%" }}>LIVE ONLINE TRAINING- NGN 80,000</span>
  </label>
  <label className="flex items-center mb-2 mr-4" style={{ marginRight: "2%" }}>
    <input
      type="radio"
      name="paymentOption"
      value="members"
      checked={selectedOption === "members"}
      onChange={() => handleOptionChange("members")}
    />
    <span className="text-lg font-bold text-green-600"  style={{ marginLeft: "5%" }}>LIVE PHYSICAL TRAINING  - NGN 100,000</span>
  </label>
  {/* {selectedOption === "members" && (
    <div className="checkout-field mb-4">
      <label className="text-lg font-bold" style={{color:"black", marginTop: "2%"}}> Enter your Membership No.</label>
      <input
        type="text"
        value={membershipNo}
        onChange={(e) => setMembershipNo(e.target.value)}
        required
        className="border border-gray-300 rounded-lg p-2 w-full"
        style={ { color: 'black'  }}
      />
    </div>
  )} */}

</div>

        <div className="checkout"  style={{marginLeft: "10%", width:"85%"}}>
          <div className="checkout-form">
            <div className="checkout-field mb-4">
              <label className="text-lg font-bold" style={{ marginRight: "1%" }}>Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="border border-gray-300 rounded-lg p-2 w-full"
              />
            </div>
            <div className="checkout-field mb-4">
              <label className="text-lg font-bold" style={{ marginRight: "1%" }}>Email</label>
              <input
                type="text"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border border-gray-300 rounded-lg p-2 w-full"
              />
            </div>
            <div className="checkout-field mb-4">
              <label className="text-lg font-bold" style={{ marginRight: "1%" }}>Phone</label>
              <input
                type="text"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="border border-gray-300 rounded-lg p-2 w-full"
              />
            </div>
            <PaystackButton
              className="paystack-button bg-red-600 rounded-lg px-6 py-4 text-lg font-bold shadow-lg cursor-pointer"   style={{ width: "100%", backgroundColor: "red", }}

              {...componentProps}
            />
          </div>
        </div>
      </div>
    </div>
  );
  
  
  
};

export default AbujaPayment;
