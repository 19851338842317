import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PaymentCheck.css';
import styles from '../styles/Profile.module.css';

const PaymentCheck = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [paidUsers, setPaidUsers] = useState([]);
  const [isChecking, setIsChecking] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('/Users.json'); // Adjust the path if needed to reflect the path
        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }
        const data = await response.json();

        // Filter users to keep only the highest membership type
        const filteredUsers = Object.values(data.reduce((acc, user) => {
          const nameKey = `${user.firstName} ${user.lastName}`.toLowerCase();
          if (!acc[nameKey] || (user.membershipType === 'Fellow' && acc[nameKey].membershipType !== 'Fellow')) {
            acc[nameKey] = user;
          }
          return acc;
        }, {}));

        setPaidUsers(filteredUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  // okay payment and handle check
  const handleCheck = async () => {
    setIsChecking(true);
    setTimeout(async () => {
      const trimmedFirstName = firstName.trim().toLowerCase();
      const trimmedLastName = lastName.trim().toLowerCase();

      const user = paidUsers.find(
        (user) =>
          (user.firstName.toLowerCase() === trimmedFirstName && user.lastName.toLowerCase() === trimmedLastName) ||
          (user.firstName.toLowerCase() === trimmedLastName && user.lastName.toLowerCase() === trimmedFirstName)
      );

      if (user) {
        navigate('/register', {
          state: {
            firstName: user.firstName,
          lastName: user.lastName,
            paymentAmount: user.paymentAmount,
            status: user.membershipType,
            membershipNo: user.membershipNo,
          },
        });
      } else {
        setErrorMessage('User not found or payment not verified.');
      }
      setIsChecking(false);
    }, 1000); // Adjust the timeout duration as needed
  };

  return (
    <div className="payment-check flex justify-center items-center h-screen">
      <div className={`${styles.glass} w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 p-6`}>
        <div className="title flex flex-col items-center">
          <h4 className="text-5xl font-bold">Check Payment</h4>
          <span className="py-4 text-xl w-2/3 text-center text-gray-500">
            Enter your details to check payment status
          </span>
        </div>

        <form className="py-1 flex flex-col items-center" onSubmit={(e) => { e.preventDefault(); handleCheck(); }}>
  <div className="textbox flex flex-col items-center gap-6">
    <input
      type="text"
      placeholder="First Name"
      className={styles.textbox}
      value={firstName}
      onChange={(e) => setFirstName(e.target.value)}
      required
    />
    <input
      type="text"
      placeholder="Last Name"
      className={styles.textbox}
      value={lastName}
      onChange={(e) => setLastName(e.target.value)}
      required
    />
    <button
      type="submit"
      className={`${styles.btn} w-full`}
      style={{ width: '100%' }}
      disabled={isChecking}
    >
      {isChecking ? 'Checking...' : 'Check'}
    </button>
    {errorMessage && <p className="error">{errorMessage}</p>}
  </div>
</form>

      </div>
    </div>
  );
};

export default PaymentCheck;
