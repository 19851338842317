import React, { useState, useEffect } from 'react';
import { useAuthStore } from '../store/store';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import './Dashboard.css'

const Dashboard = () => {
  const location = useLocation();
  const { username } = useAuthStore((state) => state.auth);
  const [userData, setUserData] = useState({
    status: '',
    mobile: '',
  });

  useEffect(() => {
    // Fetch user data from the database
    axios
      .get(`/api/user/${username}`)
      .then(response => {
        const { status, mobile, firstName, lastName, profile, isInducted, inductionLocation } = response.data;
        setUserData({ status, mobile, firstName,lastName, profile, isInducted, inductionLocation });
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  }, []);



  // const handleDownloadResources = () => {
  //   // Replace 'pdfFileUrl1' and 'pdfFileUrl2' with the actual URLs of your PDF files
  //   const pdfFileUrl1 = 'assets/doc/nibifm_constitution.pdf';
  //   const pdfFileUrl2 = 'assets/doc/keynote.pdf';
  
  //   // Create temporary anchor elements
  //   const link1 = document.createElement('a');
  //   const link2 = document.createElement('a');
  
  //   // Set the URLs for the anchor elements
  //   link1.href = pdfFileUrl1;
  //   link2.href = pdfFileUrl2;
  
  //   // Add download attributes to trigger the downloads
  //   link1.setAttribute('download', 'nibifm_constitution.pdf');
  //   link2.setAttribute('download', 'keynote_speakers.pdf');
  
  //   // Trigger the downloads
  //   link1.click();
  //   link2.click();
  // };
  

  const handleDownloadReceipt = () => {
    // Logic to download receipt
    // ...
  };

  const certNotready =()=>{
    alert("Certificate not ready, until after event!")
  }

  const uploadResources =()=>{
    alert("We're still working on them, kindly check back")
  }

  const handleInduction = () => {
    const lowerCaseInductionLocation = userData.inductionLocation.toLowerCase();
    
    if (userData.isInducted && (lowerCaseInductionLocation === 'abuja' || lowerCaseInductionLocation === 'akure')) {
      // Logic to download resources
      window.open('https://drive.google.com/file/d/1HRi51yeIMyKh7z67PIkbi_qoW_U5LjPa/view?usp=drive_link');
    } else {
      alert('You have to be inducted to download this file');
    }
  };
  
  
  
  //   const handleDownloadMealTag = () => {
//     const doc = new jsPDF({
//       format: [100, 150], // Set the custom paper size [width, height] in millimeters
//       unit: 'mm', // Set the unit of measurement to millimeters
//     });
  
//     const pageWidth = doc.internal.pageSize.getWidth();
//     const pageHeight = doc.internal.pageSize.getHeight();
  
//     const mealTagWidth = 80; // Adjust the width of the meal tag
//     const mealTagHeight = 100; // Adjust the height of the meal tag
  
//     const centerX = (pageWidth - mealTagWidth) / 2;
//     const centerY = (pageHeight - mealTagHeight) / 2;
  
//     doc.setFontSize(17);
//     const textWidth = doc.getStringUnitWidth('Meal Tag') * doc.internal.getFontSize() / doc.internal.scaleFactor;
//     const textX = centerX + (mealTagWidth - textWidth) / 2;
//     const textY = centerY - 5;
//     doc.text('Meal Tag', textX, textY); // Adjust the position of the text
//     doc.setTextColor(255, 0, 0);
  
//     // Define table data
//     const tableData = [
//       ['Name', userData.firstName, userData.lastName],
//       ['Status', userData.status],
//       ['Phone Number', userData.mobile],
//     ];
  
//     // Set table options
//     const tableOptions = {
//       startY: centerY + 10, // Adjust the starting position of the table
//       margin: { top: 10 },
//     };
  
//     // Add table to the document
//     doc.autoTable({
//       body: tableData,
//       ...tableOptions,
//     });
  
//     // Add red border
//     doc.setDrawColor(255, 0, 0); // Set border color to red
//     doc.setLineWidth(2); // Set border width
//     doc.rect(centerX, centerY, mealTagWidth, mealTagHeight, 'D'); // Draw rectangle as border
  
//     doc.save('meal-tag.pdf');
//   };
  
  
  
  return (
    <div className="dashboard">
      <h1 style={{ color: 'black' }}>Welcome to your Dashboard, {username}!</h1>
      <p style={{fontSize: "25px"}}>Here you can access various features:</p>
      <div>
        {/* <img alt="" src={userData.profile} /> */}
      </div>
      <Link to="/reciept">
            <button >Download Reciept</button>

      </Link>    
      <Link to="/agmtag">
            <button >Download AGM Tag</button>

      </Link>
      <Link to="/resources">
          <button onClick={{}}>Download Resources</button>
      </Link>
      

      {/* <button onClick={handleDownloadMealTag}>Download Meal Tag</button> */}
      {/* <Link to="/mealtag"> */}
      <Link to="/meal-tickets">
  <button >Download Meal Tag</button>
</Link>

<button onClick={handleInduction} >Download Stadium FM pro</button>

<a href="https://us06web.zoom.us/j/84543416161?pwd=ZLDGKWbC57luNVa9QLqCw8Quv3q2F5.1">
  <button>Access meeting link</button>
</a>


      <Link to="/dashboard">
            <button onClick={certNotready}>Download Certificate</button>

      </Link> 
      
      
      <Link style={{backgroundColor: "#d9a157"}} to="/profile">
      <button  style={{backgroundColor: "#d9a157"}}  >Update Profile</button>

      </Link>
      <Link style={{backgroundColor: "red"}} to="/login">
            <button style={{backgroundColor:"red"}} >Logout</button>

      </Link>   
    </div>
  );
};

export default Dashboard;
