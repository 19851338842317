import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import avatar from '../assets/profile.png';
import toast, { Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import { passwordValidate } from '../helper/validate'
import useFetch from '../hooks/fetch.hook';
import { useAuthStore } from '../store/store'
import { verifyPassword } from '../helper/helper'
import styles from '../styles/Username.module.css';
import { useState, useEffect } from 'react';


export default function Password() {

  const navigate = useNavigate();
  const { username } = useAuthStore(state => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const [apiData, setApiData] = useState(null);
  const [serverError, setServerError] = useState(null);
  console.log(username)


  // const [{ isLoading, apiData, serverError }] = useFetch(`http://localhost:8080/api/user/${username}`)



  // console.log('apiData:', apiData);
  // console.log('serverError:', serverError);


  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch(`http://localhost:8080/api/user/${username}`);
        const response = await fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/api/user/${username}`);

        if (!response.ok) {
          throw new Error('Request failed');
        }
        const data = await response.json();
        setApiData(data);
        setIsLoading(false);
      } catch (error) {
        setServerError(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [username]);

  console.log(process.env.REACT_APP_SERVER_DOMAIN)




  const formik = useFormik({
    initialValues : {
      password : ''
    },
    validate : passwordValidate,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit : async values => {
      
      let loginPromise = verifyPassword({ username, password : values.password })
      toast.promise(loginPromise, {
        loading: 'Checking...',
        success : <b>Login Successfully...!</b>,
        error : <b>Password Not Match!</b>
      });

      loginPromise.then(res => {
        let { token } = res.data;
        localStorage.setItem('token', token);
        navigate('/profile')
      })
    }
  })

  if(isLoading) return <h1 className='text-2xl font-bold'>isLoading</h1>;
  if(serverError) return <h1 className='text-xl text-red-500'>{serverError.message}</h1>

  return (
    <div className='register'>

<div className="container mx-auto">

<Toaster position='top-center' reverseOrder={false}></Toaster>

<div className='flex justify-center items-center h-screen'>
  <div className={styles.glass}>

    <div className="title flex flex-col items-center">
      <h3 style={{color:"black", marginBottom: "10%"}} className='text-5xl font-bold lowercase'>Hello, {apiData?.firstName || apiData?.username}</h3>
      <span className='py-4 text-xl w-2/3 text-center text-gray-500'>
        Please, enter your password.
      </span>
    </div>

    <form className='py-1' onSubmit={formik.handleSubmit}>
        <div className='profile flex justify-center py-4'>
            <img src={apiData?.profile || avatar} className={styles.profile_img} alt="avatar" />
        </div>

        <div className="textbox flex flex-col items-center gap-6">
            <input {...formik.getFieldProps('password')} className={styles.textbox} type="text" placeholder='Password' />
            <button style={{width:"100%", backgroundColor: "#3d7c4c"}} className={styles.btn} type='submit'>Sign In</button>
        </div>

        <div className="text-center py-4">
          <span className='text-gray-500'>Forgot Password? <Link className='text-red-500' to="/recovery"> Recover Now</Link></span>
        </div>

    </form>

  </div>
</div>
</div>

</div>
   
  )
}
